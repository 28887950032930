import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import RouteList from "./components/App/RouteList";
import "./App.css";
import "react-toastify/dist/ReactToastify.min.css";

class App extends Component {
  state = {};

  render() {
    return (
      <React.Fragment>
        <main>{/* <RouteList /> */}</main>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default App;
